import React, { useContext, useEffect, useState, useRef } from "react";

import AutoComplete from "Clutch/Atoms/Form/Elements/AutoComplete/AutoComplete";
import Typography from "Clutch/Atoms/Typography";
import { useVehicleStore } from "Clutch/Stores/VehicleStore"
import Button from "Clutch/Molecules/Button";
import ClassNames from "classnames";
import styles from "../YmmSelector.module.scss";
import { getVehiclePlaceholder } from "./tabContentUtils";
import { createClickedSegmentEvent } from "Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents";
import useSegment from "Clutch/Hooks/useSegment";
import AppName from "../../../../../appName/appName";
import { useFitmentStateContext } from "../../../../../providers/FitmentStateProvider";
import { useThemeStore } from "Clutch/Stores/ThemeStore";

const formatGarageDataForSelect = (garageData) => {
  const options = [];
  if (garageData?.length > 0) {
    garageData.forEach((vehicle) => {
      options.push({
        label:
          vehicle.raceName || vehicle.raceDisplay
            ? vehicle.raceName ?? vehicle.raceDisplay
            : `${vehicle.year ?? vehicle.yearId} ${
                vehicle.make ?? vehicle.makeDisplay
              } ${vehicle.model ?? vehicle.modelDisplay}`,
        value: vehicle.raceTypeId ? vehicle.raceTypeId : vehicle.vehicleBaseId,
      });
    });
  }

  return options;
};

const GarageTab = ({ isEnabled }) => {
  const customerVehicles = useVehicleStore(x => x.context.customerVehicles)
  const hydrated = useVehicleStore(x => x.context.hydrated)
  const selectedVehicleState = useVehicleStore(x => x.context.selectedVehicle)
  const selectCustomerProject = useVehicleStore(x => x.selectCustomerProject)
  const setIsLoading = useThemeStore(state => state.setIsLoading);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const options = formatGarageDataForSelect(customerVehicles);
  const dropdownStyles = `${ClassNames(
    styles.garageDropdown,
    options.length === 0 ? styles.streetEmptyDropdown : null
  )} lr-GarageSelector`;
  const placeholder = getVehiclePlaceholder(selectedVehicle);
  const dropdownRef = useRef();
  const { sendCustomSegmentTrackEvent } = useSegment();
  const { isFindPartsClicked, setIsFindPartsClicked } = useFitmentStateContext();

  let selectedVehicleId = null;
  if (selectedVehicle)
    selectedVehicleId =
      selectedVehicle.vehicleBaseId ?? selectedVehicle.raceTypeId;

  useEffect(() => {
    if (hydrated && customerVehicles?.length > 0) {
      const selectedRaceTypeId = selectedVehicleState?.raceTypeId;
      const selectedVehicleBaseId =
      selectedVehicleState?.vehicleBaseId;

      if (selectedRaceTypeId || selectedVehicleBaseId) {
        setSelectedVehicle(selectedVehicleState);
      } else {
        setSelectedVehicle(null);
      }
    }
  }, [
    hydrated,
    customerVehicles,
    selectedVehicleState,
    setSelectedVehicle,
  ]);

  useEffect(() => {
    if (isFindPartsClicked && isEnabled) {
      setIsFindPartsClicked(false)
      openDropdown()
    }
  }, [isFindPartsClicked, isEnabled])

  const openDropdown = () => {
    if (!selectedVehicle && dropdownRef.current)
    dropdownRef.current.focus();
  }

  const onVehicleChange = (option) => {
    let vehicleSelected = customerVehicles?.find(
      (x) => x.vehicleBaseId === option.value
    );
    if (!vehicleSelected) {
      vehicleSelected = customerVehicles?.find(
        (x) => x.raceTypeId === option.value
      );
    }
    if (vehicleSelected) {
      setIsLoading(true);
      selectCustomerProject(vehicleSelected.projectId, vehicleSelected.shopUrl);
    }
    window.location.href = vehicleSelected?.shopUrl ?? "/shop/all";
  };

  return (
    <div className={ClassNames(styles.streetTab, styles.ymmContainer)}>
      <div className={dropdownStyles}>
        <AutoComplete
          options={options}
          onChange={onVehicleChange}
          label={"Garage"}
          displayLabel={false}
          selectedValue={selectedVehicleId}
          isDisabled={options.length === 0}
          placeholder={placeholder}
          placeholderStyles={{ maxWidth: "250px" }}
          ref={dropdownRef}
          useNativeSelect={false}
        />
      </div>
      <div className={ClassNames(styles.ymmModalItem, styles.garageButton)}>
        <Button
          brand={"secondary"}
          size={"small"}
          fill
          href={selectedVehicle?.shopUrl}
          type={"button"}
          className={"lr-findPartsDesktop"}
          segmentEvent={createClickedSegmentEvent(`${AppName}_Find_Parts_Button`)}
          onClick={() => {
              openDropdown();
          }}
        >
          <Typography
            className={styles.buttonText}
            tone={"contrast"}
            size={0.75}
            font={"bold"}
            inline
            data-testid={"findPartsGarage"}
          >
            Find Parts
          </Typography>
        </Button>
      </div>
    </div>
  );
};

export default GarageTab;
